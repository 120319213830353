/**
 * Before you edit these, read the documentation on how these files are compiled:
 * https://docs.phpvms.net/developers/building-assets
 *
 * Edits here don't take place until you compile these assets and then upload them.
 * Available providers: https://leaflet-extras.github.io/leaflet-providers/preview/
 */

const leaflet = require('leaflet');
require('leaflet-providers');
import 'leaflet.fullscreen/Control.FullScreen';
import 'leaflet.fullscreen/Control.FullScreen.css';

export default (_opts) => {
  const opts = Object.assign({
    render_elem: 'map',
    center: [29.98139, -95.33374],
    zoom: 5,
    maxZoom: 10,
    layers: [],
    set_marker: false,
    leafletOptions: {},
  }, _opts);

  const leafletOptions = Object.assign({
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: 'topleft'
    },
    center: opts.center,
    zoom: opts.zoom,
    scrollWheelZoom: false,
    providers: {},
  }, opts.leafletOptions);

  // Check if any providers are listed; if not, set the default
  if (Object.entries(leafletOptions.providers).length === 0) {
    leafletOptions.providers = {
      'OpenStreetMap.Mapnik': {},
    };
  }

  const map = leaflet.map('map', leafletOptions);

  // Define Base Layers For Control Box
  var DarkMatter = L.tileLayer.provider('CartoDB.DarkMatter');
  var NatGeo = L.tileLayer.provider('Esri.NatGeoWorldMap');
  var OpenSM = L.tileLayer.provider('OpenStreetMap.Mapnik').addTo(map);
  var WorldTopo = L.tileLayer.provider('Esri.WorldTopoMap');

  const OPENAIP_API_KEY = '5986b5983d25a01dbe1440c06a6a3137';
  // Define Additional Overlay Layers
  var OpenAIP = L.tileLayer(`https://{s}.api.tiles.openaip.net/api/data/openaip/{z}/{x}/{y}.png?apiKey=${OPENAIP_API_KEY}`, {
      attribution: '<a href="https://www.openaip.net/">openAIP Data</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-NC-SA</a>)',
      reuseTiles: true,
      minZoom: 4,
      maxZoom: 14
  }).addTo(map);
  
  var dfs = L.tileLayer('https://secais.dfs.de/static-maps/icao500/tiles/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.dfs.de/homepage/de/">DFS Deutsche Flugsicherung GmbH</a>',
    maxZoom: 18,
    minZoom: 0,
    format: 'image/png',
    transparent: true,
    reuseTiles: true,
    subdomains: ['a', 'b', 'c']
  });
  
  var faa = L.tileLayer('https://www.linair.net/map/tiles/{z}/{x}/{y}.webp', {
    maxZoom: 18,
    minZoom: 0,
          attribution: 'linair.net/map',opacity: 0.99,
          tms: true,
          preferCanvas: true,
  });

  // Define Control Groups
  var BaseLayers = {'OpenSM Mapnik': OpenSM, 'Dark Matter': DarkMatter, 'NatGEO World': NatGeo, 'World Topo': WorldTopo};
  var Overlays = {"OpenAIP Data": OpenAIP, "DFS": dfs, "FAA": faa};
  var lcontrol = L.control.layers(BaseLayers, Overlays).addTo(map);
  lcontrol.addTo(map);

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in leafletOptions.providers) {
    //leaflet.tileLayer
      //.provider(key, leafletOptions.providers[key])
      //.addTo(map);
  }

  return map;
};
